import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { DirectivesModule } from '@directives/directives.module';
import { PopUpModule } from '@components/pop-up';
import { ProductCarouselModule } from '@shared/product-carousel/product-carousel.module';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { CsIconModule } from '@components/icon';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { LoadingModule } from '@components/loading';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { IncompleChatSheetModule } from '@shared/bottom-sheet-layouts/incomplete-chat/incomplete-chat.module';
import { HomePageSheetModule } from '@shared/bottom-sheet-layouts/homepage-bottom-sheet/home-page-bottom-sheet.module';
import { FollowUpChatSheetModule } from '@shared/bottom-sheet-layouts/followup-chat/followup-chat-sheet.module';
import { PrivacyPolicySheetModule } from '@shared/bottom-sheet-layouts/privacy-policy/privacy-policy-sheet.module';
import { CureskinCashSheetModule } from '@shared/bottom-sheet-layouts/cureskin-cash-sheet/cureskin-cash.module';
import { HomeBannersModule } from '@shared/home-banners/home-banners.module';
import { AnytimeFollowupModule } from '@shared/bottom-sheet-layouts/anytime-followup/anytime-followup.module';
import { DoctorPanelModule } from '@shared/home-banners/doctor-panel/doctor-panel.module';
import { HairThinningBannerModule } from '@shared/home-banners/hair-thinning/hair-thinning.module';
import { HairFallMaleBannerModule } from '@shared/home-banners/control-hair-fall-male/hair-fall-male.module';
import { HairFallBannerModule } from '@shared/home-banners/hair-fall/hair-fall-banner.module';
import { HairDandruffBannerRegimenModule } from '@shared/home-banners/hair-dandruff-regimen/hair-dandruff-regimen.module';
import { TestimonialModule } from 'client/app/testimonial/testimonial.module';
import { CAudioModule } from '@shared/c-audio/c-audio.module';
import { TakePhotoBannerModule } from '@shared/take-photo-banner/take-photo-banner.module';
import { ComparisonSliderModule } from '@shared/comparison-slider/comparison-slider.module';
import { RegimenOfferProductBannerModule } from '@shared/regimen-offer-product-banner/regimen-offer-product-banner.module';
import { UserHomeComponent } from './user-home.component';
import { UserProfileModule } from '../../../user-profile/user-profile.module';
import { BannerScrollbarModule } from './banner-scrollbar/banner-scrollbar.module';
import { DietBannerModule } from './diet-banner/diet-banner.module';
import { InstaLiveBannerModule } from './insta-live-banner/insta-live-banner.module';
import { OrderDetailBannerModule } from './order-details-banner/order-details-banner.module';
import { CrossRegimenBannerModule } from './cross-regimen-banner/cross-regimen-banner.module';
import { NPSBannerModule } from './n-p-s-banner/n-p-s-banner.module';
import { CheckupDueBannerComponent } from './level-two-banners/checkup-due-banner/checkup-due-banner.component';
import { ReportGettingReadyComponent } from './level-two-banners/report-getting-ready/report-getting-ready.component';
import { ReportIsReadyComponent } from './level-two-banners/report-is-ready/report-is-ready.component';
import { RequestingPhotoComponent } from './level-two-banners/requesting-photo/requesting-photo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    VideoPlayerModule,
    DirectivesModule,
    VideoPlayerModule,
    ToolbarModule,
    ButtonModule,
    PopUpModule,
    UserProfileModule,
    ProductCarouselModule,
    BannerScrollbarModule,
    DietBannerModule,
    InstaLiveBannerModule,
    OrderDetailBannerModule,
    SwiperModule,
    CsIconModule,
    LoadingModule,
    CrossRegimenBannerModule,
    TestimonialModule,
    NPSBannerModule,
    MatBottomSheetModule,
    IncompleChatSheetModule,
    FollowUpChatSheetModule,
    CAudioModule,
    HomePageSheetModule,
    PrivacyPolicySheetModule,
    CureskinCashSheetModule,
    HomeBannersModule,
    AnytimeFollowupModule,
    DoctorPanelModule,
    HairThinningBannerModule,
    HairFallMaleBannerModule,
    HairFallBannerModule,
    HairDandruffBannerRegimenModule,
    RegimenOfferProductBannerModule,
    CheckupDueBannerComponent,
    ReportGettingReadyComponent,
    ReportIsReadyComponent,
    CheckupDueBannerComponent,
    RequestingPhotoComponent,
    ComparisonSliderModule,
    RegimenOfferProductBannerModule,
    TakePhotoBannerModule,
  ],
  declarations: [UserHomeComponent],
  exports: [UserHomeComponent],
})
export class UserHomeModule { }
