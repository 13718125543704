<div class="tw-w-full tw-p-4 tw-bg-white tw-mb-5" (click)="handleClick()">
  <div class="tw-w-full tw-relative tw-rounded-t-3 tw-min-h-40 tw-bg-gray-100">
    <img class="tw-rounded-3" src="https://cdn.cureskin.com/app/img/rebranded_take_photo_banner.png" alt="analysis banner">
    <div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 glass-morphism-effect tw-flex tw-items-center tw-justify-between tw-gap-4 tw-p-4 tw-rounded-b-3">
      <p *ngIf="!bannerPositionBottom" class="tw-w-full tw-text-white tw-text-400" i18n="@@getDetailedSkinAndHairAnalysis">
        Get detailed skin & hair analysis
      </p>
      <p *ngIf="bannerPositionBottom" class="tw-w-full tw-text-white tw-text-400" i18n="@@seeDetailedSkinAndHairAnalysis">
        See detailed skin & hair analysis
      </p>
      <span class="tw-bg-teal-600 tw-text-white tw-py-2 tw¡-px-4 tw-min-w-28 tw-text-center tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden" [ngClass]="{'tw-text-nowrap': userLanguage === appConfig.Shared.Languages.EN}" i18n="@@takePhoto">Take Photo</span>
    </div>
    <span class="tw-absolute tw-left-3 tw-top-3 tw-z-[8] apply-container before:tw-rounded-5 before:tw-absolute before:tw-inset-0 before:tw-z-[-1] before:tw-p-[1.5px]">
        <div class="tw-flex tw-items-center tw-justify-center tw-gap-1 tw-pl-2 tw-pr-3 tw-py-[1.4px] tw-m-0.5 tw-rounded-full" style='background-color: rgba(0,0,0,0.5)'>
          <cs-icon [iconName]="'https://cdn.cureskin.com/app/img/ai_analysis_icon.svg'" class="tw-w-4 tw-h-4"></cs-icon>
          <p class="tw-text-[9px] tw-text-white tw-uppercase tw-font-bold tw-font-body" i18n="@@aiAnalysis">ai analysis</p>
        </div>
      </span>
  </div>
</div>
